import React, { FC } from 'react';
import PageTemplate from '../atomic/templates/PageTemplate';

const HomePage: FC = () => {
  return (
    <PageTemplate title="404">
      <div
        style={{
          width: '100%',
          height: '80vh',
          margin: '10vh auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <p
          style={{
            textAlign: 'center',
            fontSize: `min(20vw,${1440 * 0.2}px)`,
          }}
        >
          404
        </p>
      </div>
    </PageTemplate>
  );
};

export default HomePage;
